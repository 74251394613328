export const setCookie = (name: string, value: string, expireDays = 90, date = new Date()) => {
  date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value}; Expires=${date.toUTCString()}; Path=/;`
}

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') return
  return `; ${document.cookie}`.split('; ' + name + '=').pop()!.split(';').shift()
}

export const getAllCookies = () => {
  if (typeof window === 'undefined') return
  return decodeURIComponent(document.cookie).split(';').map((cookie) => cookie.trim().split('=')[0])
}

export const deleteCookie = (name: string) => {
  if (typeof window === 'undefined') return
  document.cookie = `${name}=; Expires=${new Date().toUTCString()}; Path=/`
}

export const deleteAnalyticsCookies = () => {
  const allCookies = getAllCookies()

  allCookies?.forEach(name => {
    if (['_ga', '_hj'].some(prefix => name.startsWith(prefix))) deleteCookie(name)
  })
}
