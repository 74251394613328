import { CaseStudiesSectionStoryblok } from '@/schema/types.d'
import { CaseStudiesSection } from '@/components/sections'

export interface CaseStudiesSectionBlokProps {
  blok: CaseStudiesSectionStoryblok;
}

const CaseStudiesSectionBlok: React.FC<CaseStudiesSectionBlokProps> = () => (
  <CaseStudiesSection />
)

export default CaseStudiesSectionBlok
