import Image from 'next/image'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { Heading } from '@/components/atoms'
import { BlogPostStoryblok } from '@/schema/types'
import { BlogTags } from './BlogTags'

export const BlogListItem: React.FC<BlogPostStoryblok> = ({ title, image, tags, allTags, teaser }) => (
  <Stack>
    <Flex marginBottom="medium">
      <Image
        src={image.filename ?? ''}
        alt={image.alt ?? ''}
        width={1000}
        height={500}
        style={{ borderRadius: '2rem' }}
        priority
      />
    </Flex>
    <Heading level="h4" space="none" marginBottom="tiny">{title}</Heading>
    <BlogTags tags={tags} allTags={allTags}/>
    <Text fontSize="small" lineHeight={1.8}>{teaser}</Text>
  </Stack>
)
