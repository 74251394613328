import Image from 'next/image'
import { HStack, Stack, Tag, Text } from '@chakra-ui/react'

export interface LabelledImageProps {
  label: string;
  imageUrl: string;
  date: string;
  tags?: string[];
}

export const LabelledImage: React.FC<LabelledImageProps> = ({ label, imageUrl, tags }) => (
  <Stack spacing="inner">
    <Image src={imageUrl ?? ''} alt={label} />
    {tags?.length && (
      <HStack>
        {tags.map((tag, i) => (
          <Tag key={i}>{tag}</Tag>
        ))}
      </HStack>
    )}
    <Text>{label}</Text>
  </Stack>
)
