import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { HeroSectionStoryblok } from '@/schema/types.d'
import { HeroSection } from '@/components/sections'

export interface HeroSectionBlokProps {
  blok: HeroSectionStoryblok;
}

const HeroSectionBlok: React.FC<HeroSectionBlokProps> = ({ blok }) => (
  <HeroSection {...storyblokEditable(blok)} key={blok._uid} variant={blok.variant} offsetTop={blok.offset_top} image={blok.image}>
    {blok.body?.map((component) => (
      <StoryblokComponent blok={component} key={component._uid} />
    ))}
  </HeroSection>
)

export default HeroSectionBlok
