import { storyblokEditable } from '@storyblok/react'
import { Link } from '@chakra-ui/next-js'
import { Flex, Link as NativeLink } from '@chakra-ui/react'
import { ButtonStoryblok } from '@/schema/types.d'

export interface ButtonBlokProps {
  blok: ButtonStoryblok;
}

const ButtonBlok: React.FC<ButtonBlokProps> = ({ blok, ...props }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    {blok.is_external  ? (
      <NativeLink href={blok.href ?? '/'} variant={`button_${blok.variant}`} target="_blank" rel="noopener noreferrer" {...props}>
        {blok.text}
      </NativeLink>
    ): (
      <Link href={blok.href ?? '/'} variant={`button_${blok.variant}`} {...props}>{blok.text}</Link>
    )}
  </Flex >
)

export default ButtonBlok
