import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { CenterStoryblok } from '@/schema/types.d'
import { Flex } from '@chakra-ui/react'

export interface CenterBlokProps {
  blok: CenterStoryblok;
}

const CenterBlok: React.FC<CenterBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Flex gap={blok.gap} direction={blok.axis === 'horizontal' ? 'column' : 'row'} alignItems="center" basis="100%">
      {blok.items?.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
    </Flex>
  </Flex>
)

export default CenterBlok
