import colors from '@/theme/colors'

import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

export const Input = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      background: 'rgba(255, 255, 255, 0.7)',
      color: colors.darkViolet,
      display: 'flex',
      flexBasis: '100%',
      height: '4rem',
      paddingY: '1rem',
      lineHeight: '1.5rem',
      borderRadius: '2rem',
      paddingX: '1.5rem',
    },
  }),
})
