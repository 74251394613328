import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

export const Text = defineStyleConfig({
  baseStyle: {},
  variants: {
    dark: {
      color: colors.doveGrey,
    },
    light: {
      color: colors.athensGrey,
    },
  },
})
