import { storyblokEditable } from '@storyblok/react'
import { BadgeStoryblok } from '@/schema/types.d'
import { Badge, Flex } from '@chakra-ui/react'

export interface BadgeBlokProps {
  blok: BadgeStoryblok;
}

const BadgeBlok: React.FC<BadgeBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Badge>
      {blok.label}
    </Badge>
  </Flex>
)

export default BadgeBlok
