import { Dispatch, SetStateAction, useState } from 'react'
import { Flex } from '@chakra-ui/layout'
import { Heading, Input, Text } from '@/components/atoms'
import { Card } from '@chakra-ui/card'

const getReadablePrice = (price: number) => `£${Number(price.toFixed(price % 1 === 0 ? 0 : 2)).toLocaleString()}`

export type PricingCalculatorProps = {
  selectUsersLabel: string,
  selectDeliverablesLabel: string,

  priceAnnualLabel: string,
  pricePerUserLabel: string,
  pricePerDeliverableLabel: string,
  priceEstimatedTotalLabel: string,

  annualPrice: number,
  perUserPrice: number,
  perDeliverablePrice: number,
}

export const PricingCalculator: React.FC<PricingCalculatorProps> = ({
  selectUsersLabel,
  selectDeliverablesLabel,

  priceAnnualLabel,
  pricePerUserLabel,
  pricePerDeliverableLabel,
  priceEstimatedTotalLabel,

  annualPrice,
  perUserPrice,
  perDeliverablePrice,
}) => {
  const [numberOfUsers, setNumberOfUsers] = useState(20)
  const [numberOfDeliverables, setNumberOfDeliverables] = useState(6)

  const updateNumberField = (setterFn: Dispatch<SetStateAction<number>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = parseInt(e.target.value, 10)

    if (!isNaN(fieldValue)) setterFn(fieldValue)
  }

  const estimatedCost = annualPrice + (12 * numberOfUsers * perUserPrice) + (numberOfDeliverables * perDeliverablePrice)

  return (
    <>
      <Flex gap="medium">
        <Input type="number" width="100%" borderColor="black" borderWidth={2} fontWeight={500} label={selectUsersLabel} defaultValue={numberOfUsers} onChange={updateNumberField(setNumberOfUsers)} />
        <Input type="number" width="100%" borderColor="black" borderWidth={2} fontWeight={500} label={selectDeliverablesLabel} defaultValue={numberOfDeliverables} onChange={updateNumberField(setNumberOfDeliverables)} />
      </Flex>
      <Flex direction="column">
        <Flex gap="tiny" marginBottom="medium" justifyContent="space-between" alignItems="center" paddingX="tiny">
          <Flex direction="column" alignItems="center">
            <Heading level="h3" space="tiny" paddingX="tiny">{getReadablePrice(annualPrice)}</Heading>
            <Text textAlign="center" fontSize="0.6rem" fontWeight={500} space="none">{priceAnnualLabel}</Text>
          </Flex>
          <Flex>
            <Heading level="h4" space="none">+</Heading>
          </Flex>
          <Flex direction="column" alignItems="center">
            <Heading level="h3" space="tiny" paddingX="tiny">{getReadablePrice(perUserPrice)}</Heading>
            <Text textAlign="center" fontSize="0.6rem" fontWeight={500} space="none">{pricePerUserLabel}</Text>
          </Flex>
          <Flex>
            <Heading level="h4" space="none">+</Heading>
          </Flex>
          <Flex direction="column" alignItems="center">
            <Heading level="h3" space="tiny" paddingX="tiny">{getReadablePrice(perDeliverablePrice)}</Heading>
            <Text textAlign="center" fontSize="0.6rem" fontWeight={500} space="none">{pricePerDeliverableLabel}</Text>
          </Flex>
        </Flex>
        <Card marginBottom="medium">
          <Heading level="h2" align="center" space="none">{getReadablePrice(estimatedCost)}</Heading>
          <Heading level="h6" align="center" space="none">{priceEstimatedTotalLabel}</Heading>
        </Card>
      </Flex>
    </>
  )
}
