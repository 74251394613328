import { storyblokEditable } from '@storyblok/react'
import { InputStoryblok } from '@/schema/types.d'
import { Input } from '@/components/atoms'

export interface InputBlokProps {
  blok: InputStoryblok;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputBlok: React.FC<InputBlokProps> = ({ blok, onChange }) => (
  <Input
    {...storyblokEditable(blok)} key={blok._uid}
    id={blok._uid}
    name={blok.name}
    label={blok.label}
    placeholder={blok.placeholder}
    onChange={onChange}
  />
)

export default InputBlok
