import { storyblokEditable } from '@storyblok/react'
import { TextareaStoryblok } from '@/schema/types.d'
import { Textarea } from '@/components/atoms'

export interface TextareaBlokProps {
  blok: TextareaStoryblok;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextareaBlok: React.FC<TextareaBlokProps> = ({ blok, onChange }) => (
  <Textarea
    {...storyblokEditable(blok)} key={blok._uid}
    name={blok.name}
    id={blok._uid}
    label={blok.label}
    onChange={onChange}
  />
)

export default TextareaBlok
