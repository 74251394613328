import { Flex } from '@chakra-ui/react'
import { PageFooterStoryblok, PageHeaderStoryblok, PageStoryblok } from '@/schema/types.d'
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { PageBody, PageFooter, PageHeader } from '@/components/page'
import { StoryblokStory } from 'storyblok-generate-ts'

export interface PageBlokProps {
  blok: PageStoryblok;
}

const PageBlok: React.FC<PageBlokProps> = ({ blok }) => (
  <Flex direction="column" minHeight="100vh" {...storyblokEditable(blok)} key={blok._uid}>
    {blok.header?.map((headerBlok: SbBlokData) => (
      <PageHeader story={headerBlok.reference as StoryblokStory<PageHeaderStoryblok>} key={'header'} />
    ))}
    <PageBody>
      {blok.body?.map((bodyBlok: SbBlokData) => (
        <StoryblokComponent blok={bodyBlok} key={bodyBlok._uid} />
      ))}
    </PageBody>
    {blok.footer?.map((footerBlok: SbBlokData) => (
      <PageFooter story={footerBlok.reference as StoryblokStory<PageFooterStoryblok>} key={'footer'} />
    ))}
  </Flex>
)

export default PageBlok
