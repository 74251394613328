import Image from 'next/image'
import { Grid, GridItem } from '@chakra-ui/react'
import { MobileFeature } from '@/components/molecules'
import { Heading, Text } from '@/components/atoms'

export interface MobileFeature {
  title: string;
  text: string;
}

export interface MobileFeaturesSectionProps {
  title: string;
  summary: string;
  items: MobileFeature[]
  imageUrl: string;
}

export const MobileFeaturesSection: React.FC<MobileFeaturesSectionProps> = ({
  title,
  summary,
  items: [item1, item2, item3, item4],
  imageUrl,
}) => (
  <>
    <Heading level="h3" align={['left', 'center']} space="small">{title}</Heading>
    <Text fontSize="1rem" align={['left', 'center']} space="large">{summary}</Text>
    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} templateRows={['repeat(4, 1fr)', 'repeat(2, 1fr)']} gap="inner" alignItems="start">
      <GridItem display="flex" flexDirection="column" area={['1/1/2/2', '1/1/2/2', '1/1/2/2']} key={item1.title} order={1} alignSelf={['start', 'start', 'end']} alignItems={['start', 'end']}>
        <MobileFeature number={1} title={item1.title} text={item1.text} align={['left', 'right']} />
      </GridItem>
      <GridItem display="flex" flexDirection="column" area={['2/1/3/2', '1/2/2/3', '1/3/2/4']} key={item2.title} order={2} alignSelf={['start', 'start', 'end']} alignItems="start">
        <MobileFeature number={2} title={item2.title} text={item2.text} align={['left']} />
      </GridItem>
      <GridItem display="flex" flexDirection="column" area={['3/1/4/2', '2/1/3/2', '2/1/3/2']} key={item3.title} order={3} alignSelf="start" alignItems={['start', 'end']}>
        <MobileFeature number={3} title={item3.title} text={item3.text} align={['left', 'right']} />
      </GridItem>
      <GridItem display="flex" flexDirection="column" area={['4/1/5/2', '2/2/3/3', '2/3/3/4']} key={item4.title} order={4} alignSelf="start" alignItems="start">
        <MobileFeature number={4} title={item4.title} text={item4.text} align={['left']} />
      </GridItem>
      <GridItem flexDirection="column" area="1/2/3/3" display={['none', 'none', 'flex']}>
        <Image src={imageUrl ?? ''} alt="" width={1000} height={1000} priority />
      </GridItem>
    </Grid>
  </>
)

