import { Flex } from '@chakra-ui/react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { StoryblokStory } from 'storyblok-generate-ts'

import { PageHeaderStoryblok } from '@/schema/types'
import { MainMenu } from '@/components/dynamic/MainMenu'
import { PageContent } from '@/components/page/PageContent'
import { SiteLogo } from '@/components/atoms/SiteLogo'

export type PageHeaderProps = {
  story: StoryblokStory<PageHeaderStoryblok>;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ story }) => {
  if (!story.content) return null

  return (
    <Flex {...storyblokEditable(story.content)} key={story.content._uid} zIndex={99} height="enormous" marginBottom="enormous" marginTop="small">
      <PageContent direction="row" alignItems="center" justifyContent="center" gap={['medium', 'medium', 'huge', 'gigantic']} maxWidth="headerMaxWidth" paddingY="inner">
        <SiteLogo logo={story.content.logo} />
        <Flex grow={[0, 0, 1]} gap="huge" justifyContent="center" fontSize="small" fontWeight={500} order={[3, 3, 'initial']}>
          <MainMenu variant={story.slug === 'header' ? undefined : 'dark'} />
        </Flex>
        <Flex grow={[1, 1, 0]} justifyContent="end" gap="inner" right='gigantic'>
          {story.content.right?.map((component) => (
            <StoryblokComponent blok={component} key={component._uid} />
          ))}
        </Flex>
      </PageContent>
    </Flex>
  )
}
