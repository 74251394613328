import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { AsideStoryblok } from '@/schema/types.d'
import { Flex } from '@chakra-ui/react'

export interface AsideBlokProps {
  blok: AsideStoryblok;
}

const AsideBlok: React.FC<AsideBlokProps> = ({ blok }) => {
  const ratio = parseInt(blok.ratio)

  return (
    <Flex {...storyblokEditable(blok)} key={blok._uid} gap={blok.gap} wrap={['wrap', 'nowrap']}>
      <Flex direction="column" grow={blok.side === 'left' ? 0 : ratio - 1}>
        {blok.left?.map((item) => (
          <StoryblokComponent blok={item} key={item._uid} />
        ))}
      </Flex>
      <Flex direction="column" grow={blok.side === 'right' ? 0 : ratio - 1}>
        {blok.right?.map((item) => (
          <StoryblokComponent blok={item} key={item._uid} />
        ))}
      </Flex>
    </Flex>
  )
}

export default AsideBlok
