import Link from 'next/link'
import Image from 'next/image'
import Video from 'next-video'
import { Box, Flex, css } from '@chakra-ui/react'
import { render } from 'storyblok-rich-text-react-renderer'

import { BlogPostStoryblok } from '@/schema/types'
import { poppins } from '@/theme/fonts'
import fontSizes from '@/theme/font-sizes'
import { Heading, Text } from '@/components/atoms'
import { BlogTags } from '@/components/molecules/BlogTags'
import { BlogTag } from '@/components/sections'
import { StoryblokStory } from '@/components/dynamic'
import { PageContent } from '@/components/page'
import { BlogPosts } from '@/services/Storyblok.service'

const publishDateFormatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' })

export const BlogPost: React.FC<BlogPostStoryblok> = ({ title, tags, image, video, content, publish_at_date }) => (
  <PageContent alignItems="center">
    <Flex wrap="nowrap" gap="huge" alignItems="start">
      <Flex direction="column" maxWidth={['auto', 'auto', '50rem']}>
        <Flex direction="column" marginBottom="huge">
          <Flex marginBottom="medium">
            <Link href="/blog">
              <Heading level="h5" space="none">← Go Back</Heading>
            </Link>
          </Flex>
          {video?.filename ? (
            <Flex marginBottom="large" marginRight="-1rem" marginLeft="-1rem" borderRadius="2rem" overflow="hidden">
              <Video src={video.filename} controls />
            </Flex>
          ) : (
            <Flex marginBottom="large" marginRight="-1rem" marginLeft="-1rem" borderRadius="2rem" overflow="hidden">
              <Image src={image.filename} alt={title} width={1000} height={500} priority />
            </Flex>
          )}
          <Flex alignItems="center" marginBottom="tiny">
            <StoryblokStory story="blog_posts">
              {data => {
                if (!Array.isArray(data) || !data.length) return null

                const blogPosts = data as BlogPosts
                const allTags = blogPosts.reduce((acc, { content }) => Array.from(new Set([...acc, ...content.tags])), [] as BlogTag[])

                return (
                  <BlogTags tags={tags} allTags={allTags} />
                )
              }}
            </StoryblokStory>
            <Text marginLeft="large" space="none">
              {publishDateFormatter.format(new Date(publish_at_date))}
            </Text>
          </Flex>
          <Heading level="h1" space="medium">
            {title}
          </Heading>
          {content && (
            <Box css={css({
              fontSize: fontSizes.small,
              'h1, h2, h3, h4, h5, h6': {
                marginBottom: '1.5rem',
                fontFamily: poppins.style.fontFamily,
                fontWeight: 600,
              },
              h1: { fontSize: fontSizes.enormous },
              h2: { fontSize: fontSizes.huge },
              h3: { fontSize: fontSizes.large },
              h4: { fontSize: fontSizes.medium },
              h5: { fontSize: fontSizes.small },
              h6: { fontSize: fontSizes.tiny },
              p: { marginBottom: '2rem' },
            })}>
              {render(content)}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  </PageContent>
)
