import { Input as ChakraInput, Flex, FormLabel, PropsOf } from '@chakra-ui/react'

export type InputProps = PropsOf<typeof ChakraInput> & {
  label?: string;
}

export const Input: React.FC<InputProps> = ({ id, label, ...props }) => (
  <Flex direction="column" basis="100%" marginBottom="medium">
    {label && (
      <FormLabel htmlFor={id} margin={0} marginLeft="1rem" marginBottom="0.25rem" fontWeight={600}>
        {label}
      </FormLabel>
    )}
    <ChakraInput id={id} {...props} />
  </Flex>
)
