import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    height: '4rem',
    lineHeight: '4rem',
    borderRadius: '2rem',
    padding: '0 3.5rem !important',
    display: 'inline-flex',
  },
  variants: {
    primary: {
      color: colors.white,
      backgroundColor: colors.sandyBrown,
    },
    secondary: {
      color: colors.white,
      backgroundColor: colors.blueRibbon,
    },
    outline: {
      color: colors.sandyBrown,
      borderWidth: 2,
      borderColor: colors.sandyBrown,
    },
  },
  defaultProps: {
    variant: 'primary',
  },
})
