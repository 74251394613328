import { extendTheme } from '@chakra-ui/react'
import colors from '@/theme/colors'
import fontSizes from '@/theme/font-sizes'
import { poppins } from '@/theme/fonts'
import * as components from '@/theme/components'

const space = {
  none: '0rem',
  tiny: '0.5rem',
  small: '1rem',
  medium: '2rem',
  large: '3rem',
  huge: '4rem',
  enormous: '6rem',
  gigantic: '8rem',
}

const breakpoints = {
  base: '0em',
  sm: '32em',
  md: '48em',
  lg: '64em',
  xl: '80em',
}

const global = {
  'html, body': {
    background: colors.transparent,
    color: colors.black,
    fontSize: '0.825rem',
    lineHeight: 1.5,

    'h1, h2, h3, h4, h5, h6': {
      fontWeight: 600,
    },

    [`@media(min-width: ${breakpoints.lg})`]: {
      fontSize: '1rem',
    },

    '.next-video-container > mux-player': { height: '100%' },
  },
}

const theme = extendTheme({
  styles: { global },
  space,
  breakpoints,

  sizes: {
    ...space,

    headerMaxWidth: '80rem',
    contentMaxWidth: '80rem',
    footerMaxWidth: '80rem',
    paragraphMaxWidth: '40rem',
  },

  fonts: {
    heading: poppins.style.fontFamily,
    body: poppins.style.fontFamily,
  },

  fontSizes: {
    h1: fontSizes.gigantic,
    h2: fontSizes.enormous,
    h3: fontSizes.huge,
    h4: fontSizes.large,
    h5: fontSizes.medium,
    h6: fontSizes.tiny,

    ...fontSizes,
  },

  colors: {
    backgrounds: {
      body: colors.white,
      header: colors.transparent,
      footer: colors.black,
      logo_bar: colors.yellowOrange,
      burger_menu: colors.royalBlue,
    },
    light: {
      text: colors.doveGrey,
      heading: colors.darkViolet,
      highlight: colors.yellowOrange,
    },
    dark: {
      text: colors.athensGrey,
      heading: colors.yellowOrange,
      highlight: colors.white,
    },
  },

  components,
})

export type Theme = typeof theme;

export default theme
