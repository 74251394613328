import { storyblokEditable } from '@storyblok/react'
import { RadioStoryblok } from '@/schema/types.d'
import { Radio } from '@/components/atoms'
import { Flex } from '@chakra-ui/react'

export interface RadioBlokProps {
  blok: RadioStoryblok;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioBlok: React.FC<RadioBlokProps> = ({ blok, onChange }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Radio
      name={blok.name}
      id={blok._uid}
      label={blok.label}
      options={blok.options.map(({ _uid, ...option }) => ({ ...option, id: _uid }))}
      onChange={onChange}
    />
  </Flex>
)

export default RadioBlok
