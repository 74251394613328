import { storyblokEditable } from '@storyblok/react'
import { ToggleStoryblok } from '@/schema/types.d'
import { Toggle } from '@/components/atoms'
import { Flex } from '@chakra-ui/react'

export interface ToggleBlokProps {
  blok: ToggleStoryblok;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleBlok: React.FC<ToggleBlokProps> = ({ blok, onChange }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Toggle
      id={blok._uid}
      name={blok.name}
      label={blok.label}
      onChange={onChange}
    />
  </Flex>
)

export default ToggleBlok
