import { Box, Select as ChakraSelect, FormLabel, PropsOf, Stack } from '@chakra-ui/react'

export type SelectProps = PropsOf<typeof ChakraSelect> & {
  id: string;
  options: { label?: string, value: string, id: string }[];
  label?: string;
}

export const Select: React.FC<SelectProps> = ({ id, options, label, ...props }) => (
  <Box paddingBottom="inner">
    <Stack direction="column">
      {label && (
        <FormLabel htmlFor={id} margin={0}>
          {label}
        </FormLabel>
      )}
      <ChakraSelect id={id} {...props}>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label ?? option.value}
          </option>
        ))}
      </ChakraSelect>
    </Stack>
  </Box>
)
