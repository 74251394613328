import { Flex, Heading as ChakraHeading, PropsOf } from '@chakra-ui/react'
import { JUSTIFY_MAP, TextAlign } from '@/components/atoms/Text'

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingSpace = 'none' | 'tiny' | 'small' | 'medium' | 'large';
export type HeadingHighlight = 'none' | 'left' | 'bottom';

export type HeadingProps = PropsOf<typeof ChakraHeading> & {
  level: HeadingLevel;
  align?: TextAlign | TextAlign[];
  space?: HeadingSpace;
  basis?: PropsOf<typeof Flex>['basis'];
};

export const Heading: React.FC<HeadingProps> = ({
  children,
  level,
  variant,
  align = 'left',
  space = 'small',
  basis = '100%',
  ...props
}) => {
  if (!children) return null

  const finalSpace = children.lastIndexOf(' ')
  const wordCount = children.split(' ').length
  const text = wordCount > 3 ? `${children.substring(0, finalSpace)}\xa0${children.substring(finalSpace + 1)}` : children
  const justifyContent = Array.isArray(align) ? align.map(a => JUSTIFY_MAP[a]) : JUSTIFY_MAP[align]

  return (
    <Flex paddingBottom={space} justifyContent={justifyContent} basis={basis}>
      <ChakraHeading as={level} fontSize={level} variant={variant} textAlign={align} {...props}>
        {text}
      </ChakraHeading>
    </Flex>
  )
}
