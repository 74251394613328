import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

export const Heading = defineStyleConfig({
  baseStyle: {
    color: colors.darkViolet,
  },
  variants: {
    highlight: {
      color: colors.yellowOrange,
    },
  },
})
