import { Link } from '@chakra-ui/react'
import { BlogTag } from '@/components/sections'
import colors from '@/theme/colors'

export type BlogMenuLinkProps = {
  tag: BlogTag | null
  text: string
  isSelected: boolean
  onClick: (tag: BlogTag | null) => void
}

export const BlogMenuLink: React.FC<BlogMenuLinkProps> = ({ tag, text, isSelected, onClick }) => (
  <Link
    onClick={() => onClick(tag)}
    paddingX="medium"
    paddingY="small"
    textAlign="center"
    color={isSelected ? colors.black : colors.white}
    bg={isSelected ? colors.yellowOrange : colors.transparent}
    borderRadius="2rem"
    fontSize="1rem"
    cursor="pointer"
  >
    {text}
  </Link>
)
