import { Flex, PropsOf } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'

export type SiteLogoProps = PropsOf<typeof Flex> & {
  logo: {
    filename: string
    alt?: string
  }
}

export const SiteLogo: React.FC<SiteLogoProps> = ({ logo, ...props }) => (
  <Flex width="6rem" height="4rem" position="relative" top="-0.25rem" shrink={0} grow={0} {...props}>
    <Link href="/">
      <Image
        src={logo.filename ?? ''}
        alt={logo.alt ?? ''}
        fill
        priority
      />
    </Link>
  </Flex>
)
