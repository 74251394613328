import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { getCookie, deleteAnalyticsCookies, setCookie } from '@/lib/cookies'
import { Heading } from '@/components/atoms'
import { StoryblokStory } from './StoryblokStory'
import { CookieBannerStoryblok } from '@/schema/types'
import { StoryblokStory as IStoryblokStory } from 'storyblok-generate-ts'

type CookieDecision = 'accept' | 'reject';
type ConsentOutcome = 'granted' | 'denied';

const consentOutcomes: Record<CookieDecision, ConsentOutcome> = {
  accept: 'granted',
  reject: 'denied',
}

export const CookieBanner: React.FC = () => {
  const [display, setDisplay] = useState(false)

  const handleAction = (decision: CookieDecision) => {
    setCookie('cookieDecision', decision)

    if (decision === 'reject') {
      deleteAnalyticsCookies()
    }

    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: consentOutcomes[decision],
        analytics_storage: consentOutcomes[decision],
      })
    }

    if (window?.dataLayer) {
      window.dataLayer.push({ event: 'consent_update' })
    }

    setDisplay(false)
  }

  useEffect(() => {
    const cookieDecision = getCookie('cookieDecision')

    if (!cookieDecision) setDisplay(true)
  }, [])

  return (
    <StoryblokStory story="global/cookie-banner">
      {(story) => {
        const cookieBanner = story as IStoryblokStory<CookieBannerStoryblok>

        return (
          <Flex display={display ? 'flex' : 'none'} grow={0} shrink={1} columnGap="large" rowGap="medium" wrap="wrap" justifyContent={['center', 'center', 'start']} alignItems="center" position="fixed" bottom="1rem" left="1rem" maxWidth="calc(100% - 2rem)" bg="white" borderRadius={20} padding={['medium', 'large']} boxShadow="5px 5px 15px rgba(0, 0, 0, 0.2)">
            <Flex direction="column">
              <Link href="#" position="absolute" top={5} right={5} padding={2} cursor="pointer" onClick={() => setDisplay(false)}>
                <Image src="/assets/close.svg" alt="Close" width={13} height={13} priority />
              </Link>
              <Heading level="h4" space="small" align={['center', 'center', 'left']}>
                {cookieBanner.content.title}
              </Heading>
              <Text maxWidth="25rem" fontSize="tiny" align={['center', 'center', 'left']}>
                {cookieBanner.content.text}
              </Text>
            </Flex>
            <Flex wrap="wrap" justifyContent="center" columnGap={8} rowGap={5}>
              <Button onClick={() => handleAction('reject')}>
                {cookieBanner.content.reject_button_label}
              </Button>
              <Button onClick={() => handleAction('accept')} variant="secondary">
                {cookieBanner.content.accept_button_label}
              </Button>
            </Flex>
          </Flex>
        )
      }}
    </StoryblokStory>
  )
}
