import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

const main_menu = {
  padding: '0.75rem 1rem',
  borderBottom: '4px solid',
  display: 'flex',
  flexShrink: '1',
  color: colors.black,
  borderColor: colors.transparent,
  position: 'relative',
}

const main_menu_selected_after = {
  content: '""',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '6px',
  borderRadius: '3px',
}

const button = {
  fontWeight: 600,
  whiteSpace: 'nowrap',
  height: '4rem',
  lineHeight: '4rem',
  borderRadius: '2rem',
  padding: '0 3rem !important',
  display: 'inline-flex',
  color: colors.white,
  _hover: { textDecoration: 'none' },
}

const blog_list_item = {
  display: 'flex',
  flexWrap: 'wrap',
  _hover: { textDecoration: 'none' },
}

export const Link = defineStyleConfig({
  baseStyle: {
    color: 'inherit',
  },
  variants: {
    highlight: {
      color: colors.yellowOrange,
    },
    light: {
      color: colors.blueRibbon,
    },
    dark: {
      color: colors.royalBlue,
    },
    blog_list_item: {
      ...blog_list_item,
    },
    main_menu: {
      ...main_menu,
    },
    main_menu_selected: {
      ...main_menu,

      fontWeight: 600,

      _after: {
        ...main_menu_selected_after,

        backgroundColor: colors.black,
      },
    },
    main_menu_dark: {
      ...main_menu,

      color: [colors.black, colors.black, colors.white],
    },
    main_menu_dark_selected: {
      ...main_menu,

      fontWeight: 600,
      color: [colors.black, colors.black, colors.white],

      _after: {
        ...main_menu_selected_after,

        backgroundColor: colors.white,
      },
    },
    button_primary: {
      ...button,

      backgroundColor: colors.sandyBrown,
    },
    button_secondary: {
      ...button,

      backgroundColor: colors.blueRibbon,
    },
    button_outline: {
      ...button,

      color: colors.sandyBrown,
      borderWidth: 2,
      borderColor: colors.sandyBrown,
    },
  },
})
