import { QuoteSectionStoryblok } from '@/schema/types'
import { QuoteSection } from '@/components/sections/QuoteSection'

export interface QuoteSectionBlokProps {
  blok: QuoteSectionStoryblok
}

const QuoteSectionBlok: React.FC<QuoteSectionBlokProps> = ({ blok }) => (
  <QuoteSection quote={blok.quote} quotee={blok.quotee} />
)

export default QuoteSectionBlok
