import { NextSSGData, StoryData } from '@/services/Storyblok.service'
import { createContext, useContext } from 'react'

export const StoryblokStoryContext = createContext<NextSSGData>({})

export function StoryblokStory({ story, children }: {
  story: string;
  children: (data: StoryData) => React.ReactNode;
}): React.ReactNode {
  const data = useContext(StoryblokStoryContext)

  if (data && typeof data[story] !== 'undefined') {
    return children(data[story])
  }

  const isSsg = typeof window === 'undefined' && typeof global !== 'undefined' && global.__next_ssg_requests

  if (isSsg) {
    global.__next_ssg_requests.push(story)
  }

  return null
}
