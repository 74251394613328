import Image from 'next/image'
import { Button } from '@chakra-ui/react'

export type PageDirectionButtonProps = {
  image: string
  visible: boolean
  onClick: () => void
}

export const PageDirectionButton: React.FC<PageDirectionButtonProps> = ({ image, visible, onClick }) => (
  <Button
    key={'next'}
    onClick={onClick}
    color="black"
    bg="transparent"
    height="4rem"
    lineHeight="4rem"
    width="4rem"
    padding={0}
    visibility={visible ? 'visible' : 'hidden'}
    pointerEvents={visible ? 'auto' : 'none'}
  >
    <Image src={image ?? ''} alt="" width={16} height={16} priority />
  </Button>
)
