import { storyblokEditable } from '@storyblok/react'
import { RichtextStoryblok } from '@/schema/types.d'
import { RichText } from '@/components/atoms/RichText'
import { Flex } from '@chakra-ui/react'

export interface RichTextBlokProps {
  blok: RichtextStoryblok;
}

const RichTextBlok: React.FC<RichTextBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <RichText>
      {blok.text}
    </RichText>
  </Flex>
)

export default RichTextBlok
