import { Box, css } from '@chakra-ui/react'
import fontSizes from '@/theme/font-sizes'
import { poppins } from '@/theme/fonts'
import { render } from 'storyblok-rich-text-react-renderer'

export type RichTextProps = {
  children: React.ReactNode
}

export const RichText: React.FC<RichTextProps> = ({
  children,
}) => {
  if (!children) return null

  return (
    <Box css={css({
      fontSize: fontSizes.small,
      'h1, h2, h3, h4, h5, h6': {
        marginBottom: '1rem',
        fontFamily: poppins.style.fontFamily,
        fontWeight: 600,
      },
      h1: { fontSize: fontSizes.enormous },
      h2: { fontSize: fontSizes.huge },
      h3: { fontSize: fontSizes.large },
      h4: { fontSize: fontSizes.medium },
      h5: { fontSize: fontSizes.small },
      h6: { fontSize: fontSizes.tiny },
      p: { marginBottom: '2rem' },
      ul: { marginLeft: '1rem' },
    })}>
      {render(children)}
    </Box>
  )
}
