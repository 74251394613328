const type = {
  gigantic: '3.052rem',
  enormous: '2.441rem',
  huge: '1.953rem',
  large: '1.563rem',
  medium: '1.25rem',
  small: '1rem',
  tiny: '0.85rem',
}

export default type
