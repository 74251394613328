import { createContext, useContext, useState } from 'react'
import Image from 'next/image'
import { Flex } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { StoryblokComponent } from '@storyblok/react'

import { PageHeaderStoryblok, PageStoryblok } from '@/schema/types'
import { MainMenuItems } from '@/services/Storyblok.service'
import { SiteLogo } from '@/components/atoms/SiteLogo'
import { BurgerIcon } from '@/components/atoms/BurgerIcon'
import { StoryblokStory } from '@/components/dynamic/StoryblokStory'
import { StoryblokStory as IStoryblokStory } from 'storyblok-generate-ts'

export interface IMainMenuContext {
  currentPath: string | null;
  items: MainMenuItems;
}

export const MainMenuContext = createContext<IMainMenuContext>({ currentPath: null, items: [] })

export type MainMenuProps = {
  variant?: 'dark';
};

export const MainMenu: React.FC<MainMenuProps> = ({ variant }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const mainMenu = useContext(MainMenuContext)
  const topLevel = mainMenu.currentPath?.split('/')[0]
  const isDark = variant === 'dark'
  const transform = menuVisible ? 'translateY(0)' : 'translateY(-100%)'
  const boxShadow = '0px 5px 10px rgba(0, 0, 0, 0.25)'
  const transition = 'transform 0.15s ease 0s'
  const burgerBorder = isDark ? 'blueRibbon' : 'doveGrey'

  return (
    <Flex basis="100%" direction="column" justifyContent="space-between" alignItems="center">
      <Flex
        gap={['none', 'none', 'medium']}
        top={0}
        left={0}
        transform={[transform, transform, 'none']}
        transition={[transition, transition, 'none']}
        boxShadow={[boxShadow, boxShadow, 'none']}
        backgroundColor={['backgrounds.body', 'backgrounds.body', 'transparent']}
        fontSize={['large', 'large', 'medium']}
        position={['absolute', 'absolute', 'initial']}
        direction={['column', 'column', 'row']}
        width={['100vw', '100vw', 'auto']}
        alignItems={['start', 'start', 'center']}
      >
        <Flex gap="medium" width="100%" paddingY="medium" paddingX={['medium', 'large']} alignItems="center" justifyContent="space-between" display={['flex', 'flex', 'none']}>
          <StoryblokStory story="global/header">
            {data => {
              if (!data || Array.isArray(data)) return null

              const story = data.content as PageHeaderStoryblok

              return (
                <Flex grow={1} justifyContent="space-between">
                  <SiteLogo logo={(data?.content.logo)} />
                  <Flex grow={0} fontSize="tiny" alignItems="end">
                    {story.right?.map((component) => (
                      <StoryblokComponent blok={component} key={component._uid} />
                    ))}
                  </Flex>
                </Flex>
              )
            }}
          </StoryblokStory>
          <BurgerIcon color="black" onClick={() => setMenuVisible(!menuVisible)} />
        </Flex>

        {mainMenu.items.map((item) => {
          const reference = item.reference as IStoryblokStory<PageStoryblok>
          const isSelected = topLevel && reference.full_slug.startsWith(topLevel)
          const linkVariant = 'main_menu' + (isDark ? '_dark' : '') + (isSelected ? '_selected' : '')

          return (
            <Flex
              key={item._uid}
              borderBottomWidth={[1, 1, 0]}
              borderBottomColor={[burgerBorder, burgerBorder, 'transparent']}
              width={['100%', '100%', 'auto']}
              paddingY={['small', 'small', 'none']}
              paddingX={['medium', 'large', 'none']}
              position="relative"
            >
              <Link href={'/' + reference.full_slug} variant={linkVariant} marginLeft={['-1rem', '-1rem', 'none']} width="100%">
                {item.label}
                <Flex display={['flex', 'flex', 'none']} position="absolute" right={['medium', 'large', 'none']} top="2.5rem">
                  <Image src="/assets/chevron-right.svg" alt=">" width={8.5} height={13.5} priority />
                </Flex>
              </Link>
            </Flex>
          )
        })}
      </Flex>
      <BurgerIcon color={isDark ? 'white' : 'black'} onClick={() => setMenuVisible(!menuVisible)} />
    </Flex>
  )
}
