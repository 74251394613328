import { MobileFeaturesSectionStoryblok } from '@/schema/types'
import { MobileFeaturesSection } from '@/components/sections/MobileFeaturesSection'

export interface MobileFeaturesSectionBlokProps {
  blok: MobileFeaturesSectionStoryblok
}

const MobileFeaturesSectionBlok: React.FC<MobileFeaturesSectionBlokProps> = ({ blok }) => (
  <MobileFeaturesSection title={blok.title} summary={blok.summary} items={blok.list_items} imageUrl={blok.image.filename} />
)

export default MobileFeaturesSectionBlok
