import { PageStoryblok } from '@/schema/types'
import Head from 'next/head'

interface SEOProps {
  content?: PageStoryblok;
  canonical?: string;
}

export const SEO: React.FC<SEOProps> = ({ content, canonical }) => {
  if (!content) return null

  const formattedCanonical = canonical?.startsWith('/') ? canonical : '/' + canonical

  const { title, description, social_image, og_type } = content

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_SITE_NAME} />
      <meta property="og:type" content={og_type} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
      {canonical && (
        <>
          <meta property="og:url" content={process.env.NEXT_PUBLIC_SITE_URL + formattedCanonical} />
          <link rel="canonical" href={process.env.NEXT_PUBLIC_SITE_URL + formattedCanonical} />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {social_image && (
        <>
          <meta property="og:image" content={social_image.filename} />
          <meta property="og:image:secure_url" content={social_image.filename} />
        </>
      )}
    </Head>
  )
}
