import { Flex } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export type PageBodyProps = PropsWithChildren;

export const PageBody: React.FC<PageBodyProps> = ({ children }) => (
  <Flex grow={1} direction="column">
    {children}
  </Flex>
)
