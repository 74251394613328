import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

export const Divider = defineStyleConfig({
  baseStyle: {
    borderColor: colors.doveGrey,
  },
  variants: {
    thin: { borderWidth: 1 },
  },
})
