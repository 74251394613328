import { storyblokInit, apiPlugin } from '@storyblok/react'

// Layout
import aside from './bloks/layout/Aside.blok'
import card from './bloks/layout/Card.blok'
import center from './bloks/layout/Center.blok'
import contact_form from './bloks/layout/ContactForm.blok'
import divider from './bloks/layout/Divider.blok'
import grid from './bloks/layout/Grid.blok'
import list from './bloks/layout/List.blok'
import page from './bloks/layout/Page.blok'
import section from './bloks/layout/Section.blok'
import space from './bloks/layout/Space.blok'
import stack from './bloks/layout/Stack.blok'

// Typography
import heading from './bloks/typography/Heading.blok'
import text from './bloks/typography/Text.blok'
import rich_text from './bloks/typography/RichText.blok'

// Forms
import button from './bloks/forms/Button.blok'
import checkbox from './bloks/forms/Checkbox.blok'
import input from './bloks/forms/Input.blok'
import radio from './bloks/forms/Radio.blok'
import select from './bloks/forms/Select.blok'
import textarea from './bloks/forms/Textarea.blok'
import toggle from './bloks/forms/Toggle.blok'

// Elements
import badge from './bloks/elements/Badge.blok'
import image from './bloks/elements/Image.blok'
import link from './bloks/elements/Link.blok'
import video from './bloks/elements/Video.blok'

// Global
import global_reference from './bloks/global/GlobalReference.blok'
import global from './bloks/global/Global.blok'

// Sections
import blog_list_section from './bloks/sections/BlogListSection.blok'
import case_studies_section from './bloks/sections/CaseStudiesSection.blok'
import customer_logo_section from './bloks/sections/CustomerLogoSection.blok'
import hero_section from './bloks/sections/HeroSection.blok'
import mobile_features_section from './bloks/sections/MobileFeaturesSection.blok'
import pricing_section from './bloks/sections/PricingSection.blok'
import quote_section from './bloks/sections/QuoteSection.blok'

// Pages
import blog_post from './bloks/pages/BlogPost.blok'
import case_study from './bloks/pages/CaseStudy.blok'

storyblokInit({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  bridge: process.env.STORYBLOK_PREVIEW_MODE === 'enabled' ? true : false,
  use: [apiPlugin],
  apiOptions: { resolveNestedRelations: true },
  components: {
    aside,
    badge,
    blog_list_section,
    blog_post,
    button,
    card,
    case_studies_section,
    case_study,
    center,
    checkbox,
    contact_form,
    customer_logo_section,
    divider,
    global_reference,
    global,
    grid,
    heading,
    hero_section,
    image,
    input,
    link,
    list,
    mobile_features_section,
    page,
    pricing_section,
    quote_section,
    radio,
    rich_text,
    section,
    select,
    space,
    stack,
    text,
    textarea,
    toggle,
    video,
  },
})
