import { Flex, Text as ChakraText, PropsOf } from '@chakra-ui/react'
import fontSizes from '@/theme/font-sizes'

export type TextSpace = 'none' | 'tiny' | 'small' | 'medium' | 'large';
export type TextAlign = 'left' | 'center' | 'right';
export type TextProps = PropsOf<typeof ChakraText> & {
  variant?: 'dark' | 'light';
  level?: keyof typeof fontSizes;
  space?: TextSpace;
  align?: TextAlign | TextAlign[];
  basis?: PropsOf<typeof Flex>['basis'];
  fullWidth?: boolean;
};

export const JUSTIFY_MAP: Record<TextAlign, string> = {
  left: 'start',
  center: 'center',
  right: 'end',
}

export const Text: React.FC<TextProps> = ({
  children,
  variant,
  level = 'small',
  space = 'small',
  align = 'left',
  basis = '100%',
  fullWidth = false,
  ...props
}) => {
  if (!children) return null

  const finalSpace = children.lastIndexOf(' ')
  const wordCount = children.split(' ').length
  const text = wordCount > 3 ? `${children.substring(0, finalSpace)}\xa0${children.substring(finalSpace + 1)}` : children
  const justifyContent = Array.isArray(align) ? align.map(a => JUSTIFY_MAP[a]) : JUSTIFY_MAP[align]

  return (
    <Flex paddingBottom={space} justifyContent={justifyContent} textAlign={align} basis={basis}>
      <ChakraText
        fontSize={fontSizes[level]}
        maxWidth={fullWidth ? '100%' :'paragraphMaxWidth'}
        variant={variant}
        {...props}
      >
        {text}
      </ChakraText>
    </Flex>
  )
}
