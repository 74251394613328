import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { SectionStoryblok } from '@/schema/types.d'
import { PageContent } from '@/components/page'

export interface SectionBlokProps {
  blok: SectionStoryblok;
}

const SectionBlok: React.FC<SectionBlokProps> = ({ blok }) => (
  <PageContent {...storyblokEditable(blok)} key={blok._uid} marginBottom={blok.space}>
    {blok.items?.map((item) => (
      <StoryblokComponent blok={item} key={item._uid} />
    ))}
  </PageContent>
)

export default SectionBlok
