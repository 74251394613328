import Link from 'next/link'
import Image from 'next/image'
import { Box, Flex, css } from '@chakra-ui/react'
import { render } from 'storyblok-rich-text-react-renderer'
import { StoryblokStory as IStoryblokStory } from 'storyblok-generate-ts'

import { CaseStudyStoryblok, PageFooterStoryblok, PageHeaderStoryblok } from '@/schema/types'
import { poppins } from '@/theme/fonts'
import fontSizes from '@/theme/font-sizes'
import { Heading, Text } from '@/components/atoms'
import { StoryblokStory } from '@/components/dynamic'
import { PageBody, PageContent, PageFooter, PageHeader } from '@/components/page'

const publishDateFormatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' })

export const CaseStudy: React.FC<CaseStudyStoryblok> = ({ title, image, content, publish_at_date }) => (
  <Flex direction="column" minHeight="100vh" basis="100%">
    <StoryblokStory story="global/header">
      {header => (
        <PageHeader story={header as IStoryblokStory<PageHeaderStoryblok>} />
      )}
    </StoryblokStory>
    <PageBody>
      <PageContent alignItems="center">
        <Flex wrap="nowrap" gap="huge" alignItems="start">
          <Flex direction="column" maxWidth={['auto', 'auto', '50rem']}>
            <Flex direction="column" marginBottom="huge">
              <Flex marginBottom="medium">
                <Link href="/case-studies">
                  <Heading level="h5" space="none">← Go Back</Heading>
                </Link>
              </Flex>
              <Flex marginBottom="large" marginRight="-1rem" marginLeft="-1rem">
                <Image src={image.filename ?? ''} alt={title} width={1000} height={500} style={{ borderRadius: '2rem' }} priority />
              </Flex>
              <Flex alignItems="center" marginBottom="tiny">
                <Text marginLeft="large" space="none">
                  {publishDateFormatter.format(new Date(publish_at_date))}
                </Text>
              </Flex>
              <Heading level="h1" space="medium">
                {title}
              </Heading>
              <Box css={css({
                fontSize: fontSizes.small,
                'h1, h2, h3, h4, h5, h6': {
                  marginBottom: '1.5rem',
                  fontFamily: poppins.style.fontFamily,
                  fontWeight: 600,
                },
                h1: { fontSize: fontSizes.enormous },
                h2: { fontSize: fontSizes.huge },
                h3: { fontSize: fontSizes.large },
                h4: { fontSize: fontSizes.medium },
                h5: { fontSize: fontSizes.small },
                h6: { fontSize: fontSizes.tiny },
                p: { marginBottom: '2rem' },
              })}>
                {render(content)}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </PageContent>
    </PageBody>
    <StoryblokStory story="global/footer">
      {footer => (
        <PageFooter story={footer as IStoryblokStory<PageFooterStoryblok>} />
      )}
    </StoryblokStory>
  </Flex>
)
