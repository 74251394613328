import { storyblokEditable } from '@storyblok/react'
import { DividerStoryblok } from '@/schema/types.d'
import { Divider, Flex } from '@chakra-ui/react'

export interface DividerBlokProps {
  blok: DividerStoryblok;
}

const DividerBlok: React.FC<DividerBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Divider marginY={blok.space} orientation={blok.orientation} variant={blok.variant} />
  </Flex>
)

export default DividerBlok
