import Link from 'next/link'
import { Flex } from '@chakra-ui/react'
import { SocialButtonStoryblok } from '@/schema/types'

export type SocialIconsProps = {
  icons: SocialButtonStoryblok[]
}

export const SocialIcons: React.FC<SocialIconsProps> = ({ icons }) => (
  <Flex alignItems="center" justifyContent="center" grow={0} gap="small">
    {icons.map(icon => (
      <Link href={icon.link} key={icon._uid}>
        <Flex bg="white" width="large" height="large" padding="tiny" borderRadius="1rem" justifyContent="center" alignItems="center">
          <i className={`${icon.icon.type} ${icon.icon.icon}`} style={{color: 'black', fontSize: '25px'}}></i>
        </Flex>
      </Link>
    ))}
  </Flex>
)
