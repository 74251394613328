import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { GridStoryblok } from '@/schema/types.d'
import { Flex, SimpleGrid } from '@chakra-ui/react'

export interface GridBlokProps {
  blok: GridStoryblok;
}

const GridBlok: React.FC<GridBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <SimpleGrid columns={[1, 1, 2, parseInt(blok.columns)]} columnGap={blok.gap} rowGap="large" alignItems={blok.vertical_align}>
      {blok.items?.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
    </SimpleGrid>
  </Flex>
)

export default GridBlok
