import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { GlobalReferenceStoryblok, GlobalStoryblok } from '@/schema/types.d'
import { Flex } from '@chakra-ui/react'

export interface GlobalReferenceBlokProps {
  blok: GlobalReferenceStoryblok;
}

const GlobalReferenceBlok: React.FC<GlobalReferenceBlokProps> = ({ blok }) => {
  const content = blok.content as GlobalStoryblok | null

  return (
    <Flex {...storyblokEditable(blok)} key={blok._uid}>
      {content?.body?.map((component) => (
        <StoryblokComponent blok={component} key={component._uid} />
      ))}
    </Flex>
  )
}

export default GlobalReferenceBlok
