const colors = {
  white: 'white',
  black: 'black',
  transparent: 'transparent',

  // Unique color names generated at https://chir.ag/projects/name-that-color/
  athensGrey: '#E7E7EA',
  blueRibbon: '#196FFF',
  doveGrey: '#636363',
  fireBush: '#EB9738',
  redOrange: '#FF3928',
  royalBlue: '#4373EE',
  sandyBrown: '#F2A855',
  darkViolet: '#0B0B41',
  yellowOrange: '#FFA540',
}

export default colors
