import Image from 'next/image'
import { useState } from 'react'
import { Button, Flex, Link, SimpleGrid } from '@chakra-ui/react'

import { StoryblokStory } from '@/components/dynamic'
import { BlogTags, BlogListItem, BlogMenuLink, PageDirectionButton } from '@/components/molecules'
import { Heading, Text } from '@/components/atoms'
import { BlogPostStoryblok } from '@/schema/types'
import { BlogPosts } from '@/services/Storyblok.service'

export type BlogTag = BlogPostStoryblok['tags'][number]

const PAGE_SIZE = 6

export const BlogListSection: React.FC = () => {
  const [currentTag, setCurrentTag] = useState<BlogTag | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)

  return (
    <StoryblokStory story="blog_posts">
      {(data) => {
        if (!Array.isArray(data) || !data.length) return null

        const blogPosts = data as BlogPosts
        const tags = blogPosts.reduce((acc, { content }) => Array.from(new Set([...acc, ...content.tags])), [] as BlogTag[])
        const posts = blogPosts.reduce((result, { content, full_slug }) => (currentTag === null || content.tags.includes(currentTag) ? [...result, { ...content, full_slug }] : result), [] as BlogPostStoryblok[])
        const heroPost = posts.shift()!
        const pages = Array(Math.ceil(posts.length / PAGE_SIZE)).fill(null).map((_, i) => posts.slice(i * PAGE_SIZE, i * PAGE_SIZE + PAGE_SIZE))
        const visiblePosts = pages[currentPage]

        return (
          <Flex direction="column">
            <Flex position="relative" top="-4rem" marginBottom="large" gap="medium" alignItems="center" marginTop="-small" display={['none', 'flex']}>
              <BlogMenuLink
                tag={null}
                text="All"
                onClick={setCurrentTag}
                isSelected={currentTag === null}
              />
              {tags?.map(tag => (
                <BlogMenuLink
                  key={tag}
                  tag={tag}
                  text={tag}
                  onClick={setCurrentTag}
                  isSelected={currentTag === tag}
                />
              ))}
            </Flex>
            <Link href={heroPost.full_slug} variant="blog_list_item" marginBottom="enormous" gap="huge" marginTop={['enormous', 'none']}>
              <Flex basis={['100%', '100%', '50%']}>
                <Image
                  src={heroPost.image.filename ?? ''}
                  alt={heroPost.title}
                  width={1000}
                  height={500}
                  style={{ borderRadius: '2rem' }}
                  priority
                />
              </Flex>
              <Flex basis={['100%', '100%', '40%']} direction="column" justifyContent="center" paddingBottom="none">
                <Heading level="h5" space="small" basis="auto" textOverflow="ellipsis" whiteSpace="nowrap">{heroPost.title}</Heading>
                <BlogTags tags={heroPost.tags} allTags={tags} />
                <Text fontSize="small" lineHeight={1.8} basis="auto" space="none">{heroPost.teaser}</Text>
              </Flex>
            </Link>
            <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={['large', 'huge']}>
              {visiblePosts?.map((blogPost) => (
                <Link href={blogPost.full_slug} variant="blog_list_item" key={blogPost._uid} >
                  <BlogListItem {...blogPost} allTags={tags} />
                </Link>
              ))}
            </SimpleGrid>
            {pages.length > 1 && (
              <Flex justifyContent="center" marginTop="enormous">
                <PageDirectionButton
                  visible={currentPage > 0}
                  image="/assets/left-arrow.svg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                />
                {pages.map((_, i) => (
                  <Button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    color={i === currentPage ? 'white' : 'black'}
                    bg={i === currentPage ? 'black' : 'transparent'}
                    borderRadius="2rem"
                    height="4rem"
                    lineHeight="4rem"
                    width="4rem"
                    padding={0}
                  >
                    {i + 1}
                  </Button>
                ))}
                <PageDirectionButton
                  visible={currentPage < pages.length - 1}
                  image="/assets/right-arrow.svg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                />
              </Flex>
            )}
          </Flex>
        )
      }}
    </StoryblokStory>
  )
}
