import { storyblokEditable } from '@storyblok/react'
import { TextStoryblok } from '@/schema/types.d'
import { Text } from '@/components/atoms/Text'
import { Flex } from '@chakra-ui/react'

export interface TextBlokProps {
  blok: TextStoryblok;
}

const TextBlok: React.FC<TextBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Text level={blok.size} space={blok.space} align={blok.align} variant={blok.variant} fullWidth={blok.full_width}>
      {blok.text}
    </Text>
  </Flex>
)

export default TextBlok
