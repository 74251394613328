import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { ListStoryblok } from '@/schema/types.d'
import { Flex, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react'
import { Heading } from '@/components/atoms'

export interface ListBlokProps {
  blok: ListStoryblok;
}

const ListBlok: React.FC<ListBlokProps> = ({ blok }) => {
  const ListComponent = blok.numbered ? OrderedList : UnorderedList

  return (
    <Flex {...storyblokEditable(blok)} key={blok._uid}>
      {blok.title && (
        <Heading level="h6" paddingBottom={0}>
          {blok.title}
        </Heading>
      )}
      <ListComponent gap={blok.gap}>
        {blok.items?.map((item) => (
          <ListItem key={item._uid}>
            <StoryblokComponent blok={item} />
          </ListItem>
        ))}
      </ListComponent>
    </Flex>
  )
}

export default ListBlok
