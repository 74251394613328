import { Flex } from '@chakra-ui/react'
import { storyblokEditable } from '@storyblok/react'
import { CaseStudyStoryblok } from '@/schema/types.d'
import { CaseStudy } from '@/components/molecules'

export interface CaseStudyBlokProps {
  blok: CaseStudyStoryblok;
}

const CaseStudyBlok: React.FC<CaseStudyBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <CaseStudy {...blok} />
  </Flex>
)

export default CaseStudyBlok
