import { Heading, Text } from '@/components/atoms'
import { Flex, Grid } from '@chakra-ui/react'

export interface QuoteSectionProps {
  quote: string;
  quotee: string;
}

export const QuoteSection: React.FC<QuoteSectionProps> = ({ quote, quotee }) =>
  <Grid templateColumns="repeat(4, 1fr)" templateRows="1fr" gap="huge">
    <Flex direction="column" gridArea={['1/1/2/5', '1/1/2/5', '1/2/2/5']} position="relative">
      <Text fontSize="5rem" position="absolute" left="-3rem" top="1.5rem">“</Text>
      <Heading level="h3" space="large" color="light.highlight">{quote}</Heading>
      <Text fontSize="small" space="none">{quotee}</Text>
    </Flex>
  </Grid>

