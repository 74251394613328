import { Flex, PropsOf } from '@chakra-ui/react'

import Image from 'next/image'

export type HeroIconProps = {
  src: string
  top?: PropsOf<typeof Flex>['top']
  right?: PropsOf<typeof Flex>['right']
  bottom?: PropsOf<typeof Flex>['bottom']
  left?: PropsOf<typeof Flex>['bottom']
}

export const HeroIcon: React.FC<HeroIconProps> = ({ src, top, right, bottom, left }) => (
  <Flex
    position="absolute"
    top={top}
    right={right}
    bottom={bottom}
    left={left}
    width="enormous"
    height="enormous"
    pointerEvents="none"
    display={['none', 'none', 'none', 'flex']}
  >
    <Image src={src ?? ''} alt="" fill priority />
  </Flex>
)
