import { Radio as ChakraRadio, FormLabel, PropsOf, RadioGroup, Stack } from '@chakra-ui/react'

export type RadioProps = PropsOf<typeof RadioGroup> & {
  id: string;
  options: { label?: string; value: string; id: string }[];
  label?: string;
};

export const Radio: React.FC<RadioProps> = ({ id, options, label, ...props }) => (
  <Stack direction="column">
    {label && (
      <FormLabel htmlFor={id} margin={0}>
        {label}
      </FormLabel>
    )}
    <RadioGroup id={id} {...props}>
      <Stack direction="column">
        {options.map((option) => (
          <ChakraRadio key={option.id} value={option.value}>
            {option.label ?? option.value}
          </ChakraRadio>
        ))}
      </Stack>
    </RadioGroup>
  </Stack>
)
