import { useState } from 'react'
import { Button, Flex, Link, SimpleGrid } from '@chakra-ui/react'

import { StoryblokStory } from '@/components/dynamic'
import { PageDirectionButton, CaseStudiesItem } from '@/components/molecules'
import { CaseStudyStoryblok } from '@/schema/types'
import { CaseStudies } from '@/services/Storyblok.service'

export type CaseStudyTag = CaseStudyStoryblok['tags'][number]

const PAGE_SIZE = 4

export const CaseStudiesSection: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0)

  return (
    <StoryblokStory story="case_studies">
      {(data) => {
        if (!Array.isArray(data) || !data.length) return null

        const caseStudies = data as CaseStudies
        const posts = caseStudies.reduce((result, { content, full_slug }) => [...result, { ...content, full_slug }], [] as CaseStudyStoryblok[])
        const pages = Array(Math.ceil(posts.length / PAGE_SIZE)).fill(null).map((_, i) => posts.slice(i * PAGE_SIZE, i * PAGE_SIZE + PAGE_SIZE))
        const visiblePosts = pages[currentPage]

        return (
          <Flex direction="column">
            <SimpleGrid columns={1} spacing={['large', 'huge']}>
              {visiblePosts?.map((caseStudy) => (
                <Link href={caseStudy.full_slug} variant="blog_list_item" key={caseStudy._uid} >
                  <CaseStudiesItem {...caseStudy} />
                </Link>
              ))}
            </SimpleGrid>
            {pages.length > 1 && (
              <Flex justifyContent="center" marginTop="enormous">
                <PageDirectionButton
                  visible={currentPage > 0}
                  image="/assets/left-arrow.svg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                />
                {pages.map((_, i) => (
                  <Button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    color={i === currentPage ? 'white' : 'black'}
                    bg={i === currentPage ? 'black' : 'transparent'}
                    borderRadius="2rem"
                    height="4rem"
                    lineHeight="4rem"
                    width="4rem"
                    padding={0}
                  >
                    {i + 1}
                  </Button>
                ))}
                <PageDirectionButton
                  visible={currentPage < pages.length - 1}
                  image="/assets/right-arrow.svg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                />
              </Flex>
            )}
          </Flex>
        )
      }}
    </StoryblokStory>
  )
}
