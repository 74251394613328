import Head from 'next/head'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { getCookie, deleteAnalyticsCookies } from '@/lib/cookies'

export const GoogleTagManagerScripts: React.FC = () => {
  const [cookieConsent, setCookieConsent] = useState(false)
  const [isInternal, setIsInternal] = useState(true)

  useEffect(() => {
    const cookieDecision = getCookie('cookieDecision')
    const isInternalCookie = getCookie(`isInternal-sv-bhg-${process.env.NEXT_PUBLIC_INTERNAL_KEY}`)

    if (cookieDecision === 'accept') setCookieConsent(true)
    if (isInternalCookie !== 'true') setIsInternal(false)
  }, [])

  if (!cookieConsent) deleteAnalyticsCookies()

  if (isInternal) return null

  return (
    <>
      <Head>
        <noscript>
          <iframe title="GTM" src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }} />
        </noscript>
      </Head>
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `}}
      />
      {cookieConsent && (
        <Script
          id="consupd"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
          });
        `}}
        />
      )}
    </>
  )
}
