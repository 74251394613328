import { CustomerLogoSectionStoryblok } from '@/schema/types'
import { CustomerLogoSection } from '@/components/sections/CustomerLogoSection'

export interface CustomerLogoSectionBlokProps {
  blok: CustomerLogoSectionStoryblok
}

const CustomerLogoSectionBlok: React.FC<CustomerLogoSectionBlokProps> = ({ blok }) => (
  <CustomerLogoSection
    logos={blok.logos.map(logo => ({
      key: logo.id,
      src: logo.filename,
      alt: logo.alt,
    }))}
  />
)

export default CustomerLogoSectionBlok
