import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Card, CardBody } from '@chakra-ui/react'
import { CardStoryblok } from '@/schema/types.d'

export interface CardBlokProps {
  blok: CardStoryblok;
}

const CardBlok: React.FC<CardBlokProps> = ({ blok }) => (
  <Card variant={blok.variant} {...storyblokEditable(blok)} key={blok._uid}>
    <CardBody>
      {blok.content?.map((component) => (
        <StoryblokComponent blok={component} key={component._uid} />
      ))}
    </CardBody>
  </Card>
)

export default CardBlok
