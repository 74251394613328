import { Flex } from '@chakra-ui/react'

export type BurgerIconProps = {
  color: string;
  onClick: () => void;
};

export const BurgerIcon: React.FC<BurgerIconProps> = ({ color, onClick }) => (
  <Flex
    display={['flex', 'flex', 'none']}

    grow={0}
    justifyContent="end"
  >
    <i className="fa-solid fa-bars" onClick={onClick} style={{
      color,
      cursor: 'pointer',
      fontSize: '22px',
    }}/>
  </Flex>
)
