import { storyblokEditable } from '@storyblok/react'
import { VideoStoryblok } from '@/schema/types.d'
import Video from 'next-video'
import { Flex } from '@chakra-ui/react'

export interface VideoBlokProps {
  blok: VideoStoryblok;
}

const VideoBlok: React.FC<VideoBlokProps> = ({ blok }) => (
  <Flex display={blok.hide_on_mobile ? ['none', 'none', 'block'] : 'block'} {...storyblokEditable(blok)} key={blok._uid}>
    <Video src={blok.src?.filename ?? ''} />
  </Flex>
)

export default VideoBlok
