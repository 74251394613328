import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { StackStoryblok } from '@/schema/types.d'
import { Flex, Stack, StackDivider } from '@chakra-ui/react'

export interface StackBlokProps {
  blok: StackStoryblok;
}

const StackBlok: React.FC<StackBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Stack gap={blok.gap} divider={blok.dividers ? <StackDivider borderColor="greyedOut" /> : undefined} marginBottom={0} justifyContent="center">
      {blok.items?.map((item) => (
        <StoryblokComponent blok={item} key={item._uid} />
      ))}
      {blok.dividers && (
        <StackDivider borderColor="greyedOut" />
      )}
    </Stack>
  </Flex>
)

export default StackBlok
