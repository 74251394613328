import colors from '@/theme/colors'
import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

export const Card = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    body: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      padding: '1.5rem',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '2rem',
    },
  }),
  variants: {
    white: {
      container: {
        padding: '2rem',
        color: colors.black,
        backgroundColor: 'rgba(255, 255, 255)',

        'h1, h2, h3, h4, h5, h6': { color: colors.black },
      },
    },
    dark: {
      container: {
        color: colors.black,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',

        'h1, h2, h3, h4, h5, h6': { color: colors.black },
      },
    },
  },
})
