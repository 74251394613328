import { BlogListSectionStoryblok } from '@/schema/types.d'
import { BlogListSection } from '@/components/sections'

export interface BlogListSectionBlokProps {
  blok: BlogListSectionStoryblok;
}

const BlogListSectionBlok: React.FC<BlogListSectionBlokProps> = () => (
  <BlogListSection />
)

export default BlogListSectionBlok
