import { PropsWithChildren } from 'react'
import { Flex, css } from '@chakra-ui/react'

import colors from '@/theme/colors'
import { PageContent } from '@/components/page'
import { HeroIcon } from '@/components/atoms'
import Image from 'next/image'

const variants = {
  default: {},
  red: {
    backgroundColor: colors.redOrange,
  },
  orange: {
    backgroundColor: colors.yellowOrange,
  },
  black: {
    backgroundColor: colors.black,
  },
}

export type HeroSectionProps = PropsWithChildren<{
  offsetTop?: boolean;
  variant?: keyof typeof variants;
  image?: { filename: string; alt?: string; }
}>

export const HeroSection: React.FC<HeroSectionProps> = ({ children, image, offsetTop = false, variant = 'default' }) => (
  <Flex
    marginTop={offsetTop ? '-14rem' : 0}
    paddingTop={offsetTop ? '14rem' : 'enormous'}
    backgroundColor={colors.royalBlue}
    backgroundImage="url('/assets/section-bg.svg')"
    backgroundPosition="center"
    backgroundSize="cover"
    overflow="hidden"
    basis="100%"
    height="100%"
    color="white"
    css={css({
      'h1, h2, h3, h4, h5, h6': {
        color: 'white',
      },
    })}
    {...variants[variant]}
  >
    <PageContent
      maxWidth={image?.filename ? '100%' : 'headerMaxWidth'}
      position="relative"
      flexDirection={['column', 'column', 'row']}
      alignItems="center"
      justifyContent="center"
    >
      <HeroIcon src="/assets/icon-star.svg" top="20rem" left="-2rem" />
      <HeroIcon src="/assets/icon-heart.svg" top="-4rem" left="-10rem" />
      <HeroIcon src="/assets/icon-plus.svg" right="-4rem" bottom="6rem" />
      {image?.filename && (
        <Flex
          order={[1, 1, 0]}
          paddingTop={['none', 'none', 'huge']}
          marginTop={['-2rem', '-2rem', 'none']}
          position="relative"
          right={['medium', 'large', 'huge']}
          alignSelf="end"
        >
          <Image
            src={image.filename}
            alt={image.alt ?? ''}
            width={1000}
            height={1000}
            priority
          />
        </Flex>
      )}
      <Flex marginBottom="enormous" flexDirection="column">
        {children}
      </Flex>
    </PageContent>
  </Flex>
)
