import { storyblokEditable } from '@storyblok/react'
import { CheckboxStoryblok } from '@/schema/types.d'
import { Checkbox } from '@/components/atoms'
import { Flex } from '@chakra-ui/react'

export interface CheckboxBlokProps {
  blok: CheckboxStoryblok;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxBlok: React.FC<CheckboxBlokProps> = ({ blok, onChange }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Checkbox
      id={blok._uid}
      name={blok.name}
      defaultChecked={blok.defaultChecked ?? false}
      label={blok.label}
      onChange={onChange}
    />
  </Flex>
)

export default CheckboxBlok
