import { Box, Stack, Text } from '@chakra-ui/react'
import { Heading } from '@/components/atoms'

export type Feature = {
  title: string;
  description: string;
}
export interface FeatureListProps {
  items: Feature[];
}

export const FeatureList: React.FC<FeatureListProps> = ({ items }) => (
  <Stack spacing="inner">
    {items.map((item, i) => (
      <Box key={i}>
        <Heading level="h3">{item.title}</Heading>
        <Text>{item.description}</Text>
      </Box>
    ))}
  </Stack>
)
