import Image from 'next/image'
import { Flex, Text } from '@chakra-ui/react'
import { CaseStudyStoryblok } from '@/schema/types'
import { Heading } from '@/components/atoms'

export const CaseStudiesItem: React.FC<CaseStudyStoryblok> = ({ title, image, teaser }) => (
  <Flex marginBottom="huge" gap="huge" justifyContent="center" wrap={['wrap', 'wrap', 'nowrap']}>
    <Flex basis={['100%', '100%', '50%']} shrink={0}>
      <Image
        src={image.filename ?? ''}
        alt={image.alt ?? ''}
        width={1000}
        height={500}
        style={{ borderRadius: '2rem' }}
        priority
      />
    </Flex>
    <Flex direction="column" justifyContent="center">
      <Heading level="h2" space="none" marginBottom="tiny">{title}</Heading>
      <Text lineHeight={1.8}>{teaser}</Text>
    </Flex>
  </Flex>
)
