import { Heading } from '@chakra-ui/react'
import { Text, TextAlign } from '@/components/atoms'

export interface MobileFeatureProps {
  number: number;
  title: string;
  text: string;
  align: TextAlign | TextAlign[];
}

export const MobileFeature: React.FC<MobileFeatureProps> = ({ number, title, text, align }) => (
  <>
    <Heading as="h5" fontSize="h5" marginBottom="tiny" textAlign={align} variant="highlight_bottom">{`0${number}.`}</Heading>
    <Heading as="h5" fontSize="h5" marginBottom="small" textAlign={align}>{title}</Heading>
    <Text align={align}>{text}</Text>
  </>
)
