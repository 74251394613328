import Image from 'next/image'
import dynamic from 'next/dynamic'
import { Flex, Text } from '@chakra-ui/react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { StoryblokStory } from 'storyblok-generate-ts'
import { PageFooterStoryblok } from '@/schema/types'
import { PageContent } from '@/components/page/PageContent'

const SocialIcons = dynamic(() => import('../molecules/SocialIcons').then((mod) => mod.SocialIcons), { ssr: false })

export type PageFooterProps = {
  story: StoryblokStory<PageFooterStoryblok>;
};

export const PageFooter: React.FC<PageFooterProps> = ({ story: { content } }) => {
  if (!content) return null

  return (
    <Flex
      direction="column"
      color="dark.text"
      bg="backgrounds.footer"
      basis="100%"
      fontSize={{ base: 'small', md: 'medium' }}
      fontWeight={500}
    >
      <PageContent direction="column" paddingTop="enormous" paddingBottom="huge" gap="large" maxWidth="footerMaxWidth" fontSize={['tiny', 'small']} {...storyblokEditable(content)} key={content._uid}s>
        <Flex marginBottom="large" wrap="wrap" justifyContent={['start', 'space-between']} columnGap={['gigantic', 'large', 'gigantic']} rowGap="large">
          {content.links && (
            <Flex direction="column" shrink={0} grow={[0, 0, 1]} gap="inner">
              {content.links.map((component) => (
                <StoryblokComponent blok={component} key={component._uid} />
              ))}
            </Flex>
          )}
          {content.contact && (
            <Flex direction="column" shrink={0} grow={0} gap="inner">
              {content.contact.map((component) => (
                <StoryblokComponent blok={component} key={component._uid} />
              ))}
            </Flex>
          )}
          {content.address && (
            <Flex direction="column" shrink={0} grow={0} gap="inner">
              {content.address.map((component) => (
                <StoryblokComponent blok={component} key={component._uid} />
              ))}
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="space-between" direction={['column', 'column', 'row']} columnGap="huge" rowGap="large">
          <Flex alignItems="center" gap="large" direction={['column', 'column', 'row']}>
            <Flex width={['gigantic', 'gigantic', 'enormous']} shrink={0} grow={0}>
              <Image
                src={content.logo.filename ?? ''}
                alt={content.logo.alt ?? ''}
                width={1000}
                height={1000}
              />
            </Flex>
            {content.copyright_holder && (
              <Text align="center">
                Copyright © {new Date().getFullYear()} {content.copyright_holder} – All Rights Reserved
              </Text>
            )}
          </Flex>
          <SocialIcons icons={content.socials} />
        </Flex>
        <Flex justifyContent="center">
          <a href="https://bytehogs.com/?utm_source=storiverse.io&utm_medium=poweredby">
            <Image
              src="/assets/powered-by-bytehogs.png"
              alt="Powered By Bytehogs"
              width={85}
              height={85}
            />
          </a>
        </Flex>

      </PageContent>
    </Flex>
  )
}
