import { storyblokEditable } from '@storyblok/react'
import { Link } from '@chakra-ui/next-js'
import { Flex, Link as NativeLink } from '@chakra-ui/react'
import { LinkStoryblok } from '@/schema/types.d'

export interface LinkBlokProps {
  blok: LinkStoryblok;
}

const LinkBlok: React.FC<LinkBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    {blok.link.linktype === 'url' ? (
      <NativeLink isExternal href={blok.link.url} variant={blok.variant}>
        {blok.text}
      </NativeLink>
    ) : (
      <Link href={blok.link.cached_url} variant={blok.variant}>
        {blok.text}
      </Link>
    )}
  </Flex>
)

export default LinkBlok
