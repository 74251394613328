import { Heading } from '@/components/atoms'
import { PricingCalculator } from '@/components/molecules/PricingCalculator'
import { PricingSectionStoryblok } from '@/schema/types'
import { Button } from '@chakra-ui/button'
import { Card } from '@chakra-ui/card'

export interface PricingSectionBlokProps {
  blok: PricingSectionStoryblok
}

const PricingSectionBlok: React.FC<PricingSectionBlokProps> = ({ blok }) => (
  <Card variant="white">
    <Heading level="h3" space="large">{blok.title}</Heading>
    <PricingCalculator
      selectUsersLabel={blok.choose_number_of_users_label}
      selectDeliverablesLabel={blok.choose_number_of_deliverables_label}

      priceAnnualLabel={blok.annual_price_label}
      pricePerUserLabel={blok.per_user_price_label}
      pricePerDeliverableLabel={blok.per_deliverable_price_label}
      priceEstimatedTotalLabel={blok.estimated_total_price_label}

      annualPrice={parseFloat(blok.annual_price)}
      perUserPrice={parseFloat(blok.per_user_price)}
      perDeliverablePrice={parseFloat(blok.per_deliverable_price)}
    />
    <Button variant="secondary">
      {blok.button_label}
    </Button>
  </Card>

)

export default PricingSectionBlok
