import { storyblokEditable } from '@storyblok/react'
import { SpaceStoryblok } from '@/schema/types.d'
import { Box, Flex } from '@chakra-ui/react'

export interface SpaceBlokProps {
  blok: SpaceStoryblok;
}

const SpaceBlok: React.FC<SpaceBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Box height={blok.size}>&#8203;</Box>
  </Flex>
)

export default SpaceBlok
