import axios from 'axios'
import { useFormik } from 'formik'
import { Button, Flex, FormControl, Text as ChakraText } from '@chakra-ui/react'
import { ContactFormStoryblok } from '@/schema/types.d'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import {useState} from 'react'
import colors from '@/theme/colors'

export interface ContactFormBlokProps {
  blok: ContactFormStoryblok;
}

const ContactFormBlok: React.FC<ContactFormBlokProps> = ({ blok }) => {
  const [submitted, setSubmitted] = useState(false)
  const [formError, setFormError] = useState('')

  const formik = useFormik({
    initialValues: Object.fromEntries(blok.fields.map((field) => [field.name, undefined])),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)
      setFormError('')
      try {
        await axios.post('/api/contact', values)
        setSubmitted(true)
      } catch(e) {
        console.error(e.message)
        setFormError('Sorry! we couldn\'t send your message. Try again later')
      } finally {
        actions.setSubmitting(false)
      }
    },
  })

  if (submitted) return (
    <div>
      <ChakraText fontWeight='semibold'>Thank you for your message! We&apos;ll be in touch soon</ChakraText>
    </div>
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl display="flex" flexDirection="column">
        {blok.fields.map((item) => (
          <Flex {...storyblokEditable(blok)} key={item._uid}>
            <StoryblokComponent blok={item} onChange={formik.handleChange} value={formik.values[item.name]} />
          </Flex>
        ))}
        <ChakraText color={colors.redOrange} marginBottom={'20px'} fontWeight='semibold'>{formError}</ChakraText>
        <Button type="submit" alignSelf="start">
          {formik.isSubmitting ? 'Sending' : blok.submit_text}
        </Button>
      </FormControl>
    </form>
  )
}

export default ContactFormBlok
