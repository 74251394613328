import colors from '@/theme/colors'
import { defineStyleConfig } from '@chakra-ui/react'

export const Textarea = defineStyleConfig({
  baseStyle: {
    resize: 'none',
    background: 'rgba(255, 255, 255, 0.7)',
    color: colors.darkViolet,
    display: 'flex',
    flexBasis: '100%',
    height: '8rem',
    lineHeight: '4rem',
    borderRadius: '2rem',
    paddingX: '2rem',
  },
})
