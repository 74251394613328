import { Checkbox as ChakraCheckbox, FormLabel, PropsOf, Stack } from '@chakra-ui/react'

export type CheckboxProps = PropsOf<typeof ChakraCheckbox> & {
  id: string;
  label?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, label, ...props }) => (
  <Stack direction="row">
    <ChakraCheckbox id={id} {...props} />
    {label && (
      <FormLabel htmlFor={id} margin={0}>
        {label}
      </FormLabel>
    )}
  </Stack>
)
