import { storyblokEditable } from '@storyblok/react'
import { ImageStoryblok } from '@/schema/types.d'
import Image from 'next/image'
import { Flex } from '@chakra-ui/react'

export interface ImageBlokProps {
  blok: ImageStoryblok;
}

const ImageBlok: React.FC<ImageBlokProps> = ({ blok }) => (
  <Flex display={blok.hide_on_mobile ? ['none', 'none', 'block'] : 'block'} {...storyblokEditable(blok)} key={blok._uid}>
    <Image
      alt={blok.description}
      src={blok.image.filename ?? ''}
      width={blok.width ? parseInt(blok.width) : 100}
      height={blok.width ? parseInt(blok.height) : 100}
      priority={blok.lazy_load ? false : true}
    />
  </Flex>
)

export default ImageBlok
