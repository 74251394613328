import { Box, Switch, FormLabel, Stack, PropsOf } from '@chakra-ui/react'

export type ToggleProps = PropsOf<typeof Switch> & {
  id: string;
  label?: string;
};

export const Toggle: React.FC<ToggleProps> = ({ id, label, ...props }) => (
  <Box paddingBottom="inner">
    <Stack direction="row" spacing={3} alignItems="center">
      <Switch id={id} {...props} />
      {label && (
        <FormLabel htmlFor={id} margin={0}>
          {label}
        </FormLabel>
      )}
    </Stack>
  </Box>
)
