import { Flex } from '@chakra-ui/react'
import { storyblokEditable } from '@storyblok/react'
import { StoryblokStory as IStoryblokStory } from 'storyblok-generate-ts'
import { BlogPostStoryblok, PageFooterStoryblok, PageHeaderStoryblok } from '@/schema/types.d'

import { PageBody, PageFooter, PageHeader } from '@/components/page'
import { StoryblokStory } from '@/components/dynamic'
import { BlogPost } from '@/components/molecules/BlogPost'

export interface BlogPostBlokProps {
  blok: BlogPostStoryblok;
}

const BlogPostBlok: React.FC<BlogPostBlokProps> = ({ blok }) => (
  <Flex direction="column" minHeight="100vh" {...storyblokEditable(blok)} key={blok._uid}>
    <StoryblokStory story="global/header">
      {header => (
        <PageHeader story={header as IStoryblokStory<PageHeaderStoryblok>} />
      )}
    </StoryblokStory>
    <PageBody>
      <BlogPost {...blok} />
    </PageBody>
    <StoryblokStory story="global/footer">
      {footer => (
        <PageFooter story={footer as IStoryblokStory<PageFooterStoryblok>} />
      )}
    </StoryblokStory>
  </Flex>
)

export default BlogPostBlok
