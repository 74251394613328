import { storyblokEditable } from '@storyblok/react'
import { HeadingStoryblok } from '@/schema/types.d'
import { Heading } from '@/components/atoms'
import { Flex } from '@chakra-ui/react'

export interface HeadingBlokProps {
  blok: HeadingStoryblok;
}

const HeadingBlok: React.FC<HeadingBlokProps> = ({ blok, ...props }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <Heading
      level={blok.level}
      space={blok.space}
      align={blok.align}
      variant={blok.variant}
      {...props}
    >
      {blok.text}
    </Heading>
  </Flex>
)

export default HeadingBlok
