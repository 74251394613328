import colors from '@/theme/colors'
import { Badge, Flex, PropsOf } from '@chakra-ui/react'

const TAG_COLORS = [
  colors.royalBlue,
  colors.yellowOrange,
  colors.redOrange,
  colors.sandyBrown,
  colors.fireBush,
]

type BlogTagsProps = PropsOf<typeof Flex> & {
  tags: string[]
  allTags: string[]
}

export const BlogTags: React.FC<BlogTagsProps> = ({ tags, allTags, ...props }) => (
  <Flex gap="medium" {...props}>
    {tags.map(tag => (
      <Badge
        key={tag}
        paddingY="0.25rem"
        borderRadius="0.25rem"
        fontWeight={600}
        fontSize="1rem"
        color={TAG_COLORS[allTags?.findIndex(t => tag === t) ?? 0]}
      >
        {tag}
      </Badge>
    ))}
  </Flex>
)

