import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { GlobalReferenceStoryblok } from '@/schema/types.d'
import { Flex } from '@chakra-ui/react'

export interface GlobalReferenceBlokProps {
  blok: GlobalReferenceStoryblok;
}

const GlobalReferenceBlok: React.FC<GlobalReferenceBlokProps> = ({ blok }) => (
  <Flex {...storyblokEditable(blok)} key={blok._uid}>
    <StoryblokComponent blok={blok.reference} />
  </Flex>
)

export default GlobalReferenceBlok
