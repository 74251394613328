import Image from 'next/image'
import { Flex } from '@chakra-ui/react'

export interface CustomerLogoSectionProps {
  logos: {
    key: number
    src: string
    alt?: string
  }[]
}

export const CustomerLogoSection: React.FC<CustomerLogoSectionProps> = ({ logos }) => (
  <Flex bg="backgrounds.logo_bar" overflowX="hidden">
    <Flex justifyContent="space-between" marginX="-huge">
      {logos.map(logo => (
        <Flex key={logo.key} basis="gigantic" height="enormous" shrink={0} marginX="large">
          <Image
            src={logo.src ?? ''}
            alt={logo.alt ?? ''}
            width={1000} height={1000}
            priority
          />
        </Flex>
      ))}
    </Flex>
  </Flex>
)
