import { Flex, PropsOf } from '@chakra-ui/react'

export type PageContentProps = PropsOf<typeof Flex>

export const PageContent: React.FC<PageContentProps> = ({ children, ...props }) => (
  <Flex
    grow={1}
    paddingX={['medium', 'large', 'huge']}
    maxWidth="contentMaxWidth"
    direction="column"
    width="100%"
    margin="0 auto"
    {...props}
  >
    {children}
  </Flex>
)
